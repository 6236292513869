import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ApiKeys from './ApiKeys/ApiKeys';
import Documentation from './Documentation/Documentation';

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  navBar: {
    backgroundColor: '#fff',
    color: 'black'
  }
});

function Api(props) {
  const { classes } = props;
  const [value, setValue] = useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.navBar}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Api keys" />
          <Tab label="Documentation" />
        </Tabs>
      </AppBar>
      {value === 0 && <ApiKeys />}
      {value === 1 && <Documentation />}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Api);
