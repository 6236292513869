import { apiKey } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  apiKey: {},
  apiKeys: [],
  loading: false,
  error: undefined,
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_API_KEY_PENDING':
      return { ...state, loading: true };
    case 'CREATE_API_KEY_FULFILLED':
      NotificationManager.success('Successfully Created ApiKey');
      action.meta && action.meta(action.payload.data);
      return { ...state, apiKeys: [action.payload.data, ...state.apiKeys], loading: false };
    case 'CREATE_API_KEY_REJECTED':
      NotificationManager.error('ApiKey Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_API_KEYS_PENDING':
      return { ...state, loading: true };
    case 'LIST_API_KEYS_FULFILLED':
      return { ...state, apiKeys: action.payload.data, did_get: true, loading: false };
    case 'LIST_API_KEYS_REJECTED':
      NotificationManager.error('Network error');
      return { ...state, loading: false };
    case 'DELETE_API_KEY_FULFILLED':
      let _id = action.payload.data._id;
      let index = state.apiKeys.findIndex(apiKey => apiKey._id === _id);
      let apiKeys = [...state.apiKeys];
      apiKeys.splice(index, 1);
      NotificationManager.success('Successfully Deleted ApiKey');
      action.meta && action.meta();
      return { ...state, apiKeys };
    case 'DELETE_API_KEY_REJECTED':
      NotificationManager.error('An error occured');
      return state;
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_API_KEY',
  payload: apiKey.create(body),
  meta: cb
});

export const del = (id, cb) => ({
  type: 'DELETE_API_KEY',
  payload: apiKey.delete({}, id),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_API_KEYS',
  payload: apiKey.list(body),
  meta: cb
});
