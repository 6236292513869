import React, { useState, Fragment } from 'react';
import {
  Typography,
  Divider,
  Card,
  withStyles,
  Grid,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Input
} from '@material-ui/core';
import { DateRangeOutlined, PersonAddOutlined } from '@material-ui/icons';
import { create, edit } from '../../reducers/passReducer';
import { list as listUsers, create as createUser } from '../../reducers/userReducer';
import { list as listLocations } from '../../reducers/locationReducer';
import { list as listLocks } from '../../reducers/lockReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddUserModal from '../Users/AddUserModal';
import Select from 'react-select';
import classNames from 'classnames';
import { SingleDatePicker } from 'react-dates';
import { hours, days as weekdays } from '../../constants';
import moment from 'moment';

const styles = theme => ({
  card: {
    minHeight: '80vh',
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
    alignItems: 'center',
    overflow: 'visible'
  },
  dividerTopMargin: {
    marginTop: theme.spacing.unit * 3
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#9c9c9c'
  },
  datePicker: {
    width: '200px'
  },
  calendarButton: {
    margin: 0,
    borderRadius: 0,
    width: '100%'
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const CreatePass = props => {
  const {
    classes,
    did_get_users,
    listUsers,
    did_get_locations,
    listLocations,
    did_get_locks,
    listLocks,
    userLoading,
    loading
  } = props;
  let { selectedPass } = props;

  if (!selectedPass) selectedPass = {};

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(selectedPass.selectedUsers || []);
  const [selectedLocation, setSelectedLocation] = useState(selectedPass.selectedLocation || null);
  const [selectedLocks, setSelectedLocks] = useState(selectedPass.selectedLocks || []);
  const [name, setName] = useState(selectedPass.name);
  const [startDate, setStartDate] = useState(selectedPass.startDate || null);
  const [startDateFocus, setStartDateFocus] = useState(null);
  const [endDate, setEndDate] = useState(selectedPass.endDate || null);
  const [endDateFocus, setEndDateFocus] = useState(null);
  const [startTime, setStartTime] = useState(
    selectedPass.startTime || { value: 480, label: '08:00' }
  );
  const [endTime, setEndTime] = useState(selectedPass.endTime || { value: 1020, label: '17:00' });
  const [days, setDays] = useState(selectedPass.days || []);
  const [continuous, setContinous] = useState(
    selectedPass.continuous !== undefined ? selectedPass.continuous : true
  );

  const _id = selectedPass._id;

  const createUser = user => {
    props.createUser(user, () => {
      setUserModalOpen(false);
    });
  };

  if (!did_get_users) listUsers();
  if (!did_get_locks) listLocks();
  if (!did_get_locations) listLocations();

  const users = props.users.map(user => ({ value: user._id, label: user.full_name }));
  const locations = props.locations.map(location => ({
    value: location._id,
    label: location.name
  }));

  let locks = props.locks.map(lock => ({
    value: lock._id,
    label: lock.name,
    location: lock.location && lock.location._id
  }));

  const onChangeUsers = e => setSelectedUsers(e);
  const onChangeLocation = e => {
    setSelectedLocation(e);
    setSelectedLocks([]);
  };
  const onChangeLocks = value => setSelectedLocks(value);
  const onChangeName = e => setName(e.target.value);
  const onStartDateChange = value => setStartDate(value);
  const onEndDateChange = value => setEndDate(value);
  const onChangeStartTime = value => setStartTime(value);
  const onChangeEndTime = value => setEndTime(value);
  const onChangeDays = value => setDays(value);
  const onChangeContinuous = value => setContinous(!continuous);

  if (selectedLocation) locks = locks.filter(lock => lock.location === selectedLocation.value);

  const submit = () => {
    if(!selectedLocks || !selectedLocks.length) return Notification
    let body = {
      start_date: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      locks: selectedLocks.map(lock => lock.value),
      users: selectedUsers.map(user => user.value),
      continuous,
      weekdays: days.map(day => ({
        day: day.value,
        start_time: startTime.value,
        end_time: endTime.value
      })),
      name,
      _id
    };

    if (_id) {
      props.edit(body, _id, () => {
        props.closeModal();
      });
    } else {
      props.create(body, () => {
        props.history ? props.history.push('/dashboard/passes') : props.closeModal();
      });
    }
  };

  return (
    <div>
      <Modal open={userModalOpen} onClose={() => setUserModalOpen(false)}>
        <AddUserModal loading={userLoading} create={createUser} />
      </Modal>
      <Card className={classes.card}>
        <div className={classes.cardContent}>
          <Typography variant="title" className={classes.title}>
            {'1. Pass Details'}
          </Typography>
          <Divider />
          <Grid container>
            <Grid item sm={4} md={4}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">Pass Name</InputLabel>
                <Input autoFocus id="name" name="name" value={name} onChange={onChangeName} />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Grid item xs={12} md={5}>
              <Select
                closeMenuOnSelect={false}
                isMulti
                value={selectedUsers}
                onChange={onChangeUsers}
                options={users}
                placeholder="Select Users"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="text" onClick={() => setUserModalOpen(true)}>
                <PersonAddOutlined className="mr-1" />
                Create a new user
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Grid item xs={12} md={3}>
              <Select
                value={selectedLocation}
                onChange={onChangeLocation}
                options={locations}
                placeholder="Select Location"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {selectedLocation && (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedLocks}
                  onChange={onChangeLocks}
                  options={locks}
                  placeholder="Select Locks"
                />
              )}
            </Grid>
          </Grid>
          <Typography
            variant="title"
            className={classNames(classes.dividerTopMargin, classes.title)}
          >
            {'2. Pass Timing'}
          </Typography>
          <Divider />
          <Grid container spacing={16} className={classes.dividerTopMargin}>
            <Grid item sm={3} md={4}>
              <SingleDatePicker
                showClearDate
                placeholder={'Start Date / Now'}
                date={startDate}
                onDateChange={onStartDateChange}
                focused={startDateFocus}
                onFocusChange={({ focused }) => setStartDateFocus(focused)}
                id="startdate"
                numberOfMonths={1}
                displayFormat={'DD.MM.YYYY'}
                customInputIcon={<DateRangeOutlined />}
                hideKeyboardShortcutsPanel
                openDirection="up"
                firstDayOfWeek={1}
              />
            </Grid>
            <Grid item sm={3} md={4}>
              <SingleDatePicker
                showClearDate
                placeholder={'End Date / Permanent'}
                date={endDate}
                onDateChange={onEndDateChange}
                focused={endDateFocus}
                onFocusChange={({ focused }) => setEndDateFocus(focused)}
                id="enddate"
                numberOfMonths={1}
                displayFormat={'DD.MM.YYYY'}
                customInputIcon={<DateRangeOutlined />}
                hideKeyboardShortcutsPanel
                openDirection="up"
                firstDayOfWeek={1}
                calendarInfoPosition="bottom"
                renderCalendarInfo={() => (
                  <Button
                    variant="outlined"
                    className={classes.calendarButton}
                    color={'default'}
                    onClick={() => {
                      setEndDate(null);
                      setEndDateFocus(false);
                    }}
                  >
                    No End Date
                  </Button>
                )}
              />
            </Grid>
          </Grid>
          <Typography
            variant="title"
            className={classNames(classes.dividerTopMargin, classes.title)}
          >
            {'3. Pass Recurrence'}
          </Typography>
          <Divider />
          <Grid container spacing={16} className={classes.dividerTopMargin}>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox checked={continuous} onChange={onChangeContinuous} color="primary" />
                }
                label="Continuous (24/7)"
              />
            </Grid>
            {!continuous && (
              <Fragment>
                <Grid item xs={6} md={3}>
                  <Select
                    value={startTime}
                    onChange={onChangeStartTime}
                    options={hours}
                    placeholder="Select Start Time"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Select
                    value={endTime}
                    onChange={onChangeEndTime}
                    options={hours}
                    placeholder="Select End Time"
                  />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    value={days}
                    onChange={onChangeDays}
                    options={weekdays}
                    placeholder="Select Days"
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            className="mt-3"
            disabled={loading}
            onClick={submit}
          >
            {loading && <CircularProgress size={24} className={classes.loader} />}
            {_id ? 'Edit' : 'Create'} Pass
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapState = state => ({
  loading: state.passReducer.loading,
  userLoading: state.userReducer.loading,
  did_get_users: state.userReducer.did_get,
  users: state.userReducer.users,
  did_get_locations: state.locationReducer.did_get,
  locations: state.locationReducer.locations,
  did_get_locks: state.lockReducer.did_get,
  locks: state.lockReducer.locks
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      edit,
      listUsers,
      createUser,
      listLocations,
      listLocks
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(CreatePass)
);
