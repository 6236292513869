import React from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import moment from 'moment';

const styles = theme => ({
  root: {
    position: 'absolute',
    maxHeight: '80vh',
    maxWidth: '100vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit,
    outline: 'none',
    top: '50vh',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  table: {
    overflow: 'scroll',
    maxHeight: '70vh',
    display: 'block',
    whiteSpace: 'nowrap'
  }
});

const format_time = date => moment(date).format('YYYY-MM-DD HH:mm:ss');

const commands = [
  {
    command: 'CPLIN',
    color: 'green',
    text: 'Unlock'
  },
  {
    command: 'CPLOUT',
    color: 'red',
    text: 'Lock'
  },
  {
    command: 'BUZZ',
    color: 'black',
    text: 'Buzz'
  },
  {
    command: 'LEDR',
    color: 'red',
    text: 'Led R'
  },
  {
    command: 'LEDG',
    color: 'green',
    text: 'Led G'
  },
  {
    command: 'LEDB',
    color: 'blue',
    text: 'Led B'
  }
];

const format_command = command => {
  let color = 'black';
  let index = commands.findIndex(c => c.command === command);
  if (index !== -1) {
    color = commands[index].color;
    command = commands[index].text;
  }
  return <Typography style={{ color }}>{command}</Typography>;
};

const AccessLogsModal = props => {
  const { classes, selectedLock } = props;

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Action</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedLock &&
            selectedLock.logs &&
            selectedLock.logs.map(log => (
              <TableRow key={log._id}>
                <TableCell>{format_command(log.command)}</TableCell>
                <TableCell>{log.user ? log.user.full_name : 'Admin'}</TableCell>
                <TableCell>{format_time(log.createdAt)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(AccessLogsModal);
