export const endpoints = [
  {
    name: 'Create user',
    method: 'POST',
    route: '/api/v1/user',
    parameters: [
      {
        name: 'full_name',
        required: true,
        description: 'Full name of the user you are creating',
        type: 'string',
        exampleValue: 'John Doe'
      },
      {
        name: 'email',
        required: true,
        description: 'Email address of the user you are creating, must be unique',
        type: 'string',
        exampleValue: 'email@example.com'
      },
      {
        name: 'phone_number',
        required: true,
        description: 'Phone number of the user you are creating, must be unique',
        type: 'string',
        exampleValue: '+4930123456789'
      },
      {
        name: 'send_email',
        required: false,
        description:
          'If this value is true, confirmation email will be sent to user with instructions on how to set their password for 24Solution app',
        type: 'bool',
        exampleValue: 'true'
      }
    ],
    response: {
      _id: '5d08da948f90a513ae8ee03f',
      createdAt: '2019-06-18T12:35:32.896Z',
      full_name: 'John Doe',
      email: 'email@example.com',
      phone_number: '+4930123456789',
      company: '5cd43341f047c8133ee58b2a',
      __v: 0
    },
    headers: [
      {
        name: 'Authorization',
        value: 'ApiKey-v1 {your-api-key-here}'
      }
    ]
  },
  {
    name: 'Get users locks',
    method: 'GET',
    route: '/api/v1/user/{user_id}/list_locks',
    parameters: [],
    headers: [
      {
        name: 'Authorization',
        value: 'ApiKey-v1 your-api-key-here'
      }
    ],
    response: [
      {
        _id: '5cd9813f14de9f2a014aea33',
        name: 'New Lock',
        MAC: '00:00:00:00:00:00',
        floor: 0,
        location: {
          _id: '5cd53533e97ef81bc8536aa1',
          name: 'New Location',
          address: 'Address 1, 12345'
        }
      }
    ]
  }
];
