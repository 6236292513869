import React, { useState } from 'react';
import {
  Typography,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
  CircularProgress
} from '@material-ui/core';
import { add_package } from '../../reducers/gatewayReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddPackageModal = props => {
  const { classes, loading, add_package, onClose } = props;
  const [_package, setPackage] = useState('');

  const handleSubmit = e => {
    e && e.preventDefault();
    add_package({ code: _package }, () => {
      onClose();
    });
  };

  return (
    <div className={classes.paper}>
      <Typography variant="h5">Add Package</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="code">Package Code</InputLabel>
          <Input
            autoFocus
            id="code"
            name="code"
            autoComplete="code"
            value={_package}
            onChange={e => setPackage(e.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {'Add Package'}
        </Button>
      </form>
    </div>
  );
};

const mapState = state => ({
  loading: state.gatewayReducer.loading
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      add_package
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(AddPackageModal)
);
