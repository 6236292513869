import React, { useState } from 'react';
import { Modal, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddApiKeyModal from './AddApiKeyModal';
import Table from '../../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, list, del } from '../../../reducers/apiKeyReducer';
import moment from 'moment';
import Confirmation from '../../../components/Confirmation/Confirmation';
import KeyCreatedModal from './KeyCreatedModal';

const styles = theme => ({});

const ApiKeys = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [keyCreatedModal, setKeyCreatedModal] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const { apiKeys, did_get, loading } = props;

  if (!did_get) props.list();

  const create = apiKey => {
    props.create(apiKey, key => {
      setModalOpen(false);
      setApiKey(key);
      setKeyCreatedModal(true);
    });
  };

  const del = () => {
    props.del(selectedApiKey._id, () => {
      setDeleteDialogOpen(false);
    });
  };

  const onCreate = () => {
    setSelectedApiKey(null);
    setModalOpen(true);
  };

  const onDelete = apiKey => {
    setSelectedApiKey(apiKey);
    setDeleteDialogOpen(true);
  };

  const headers = [
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: data => moment(data.createdAt).format('DD.MM.YYYY')
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Public ID',
      selector: '_id',
      sortable: true
    }
  ];

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddApiKeyModal loading={loading} selectedApiKey={selectedApiKey} create={create} />
      </Modal>
      <Modal open={keyCreatedModal} onClose={() => setKeyCreatedModal(false)}>
        <KeyCreatedModal apiKey={apiKey} />
      </Modal>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to delete this apiKey?"
          description="This action will permanently delete apiKey, and it will no longer be valid on all apps that use it."
          actions={[
            {
              text: 'Delete Permanently',
              color: 'secondary',
              onClick: () => del()
            },
            {
              text: 'Cancel',
              color: 'primary',
              onClick: () => setDeleteDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setDeleteDialogOpen(false)}
          agreeText="Delete Permanently"
          agreeAction={() => {}}
        />
      </Dialog>
      <Table
        loading={loading}
        data={apiKeys}
        headers={headers}
        create={onCreate}
        delete={onDelete}
        title="ApiKeys"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.apiKeyReducer.loading,
  apiKeys: state.apiKeyReducer.apiKeys,
  did_get: state.apiKeyReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      del
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(ApiKeys)
);
