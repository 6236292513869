import { company } from '../api';
import { NotificationManager } from 'react-notifications';

const initalState = {
  loading: false,
  error: {}
};

export default function authReducer(state = initalState, action) {
  switch (action.type) {
    case 'REGISTER_PENDING':
      return {
        ...state,
        loading: true
      };
    case 'REGISTER_FULFILLED':
      NotificationManager.success('Successfully Registered');
      action.meta && action.meta();
      return {
        ...state,
        loading: false,
        error: {}
      };
    case 'REGISTER_REJECTED':
      return {
        ...state,
        error: action.payload.response.data,
        loading: false
      };
    case 'LOGIN_PENDING':
      return {
        ...state,
        loading: true
      };
    case 'LOGIN_FULFILLED':
      localStorage.setItem('token', action.payload.data.token);
      action.meta && action.meta();
      return {
        ...state,
        loading: false,
        error: {}
      };
    case 'LOGIN_REJECTED':
      return {
        ...state,
        error: action.payload.response.data,
        loading: false
      };
    case 'RESET_PASSWORD_EMAIL_PENDING':
      return {
        ...state,
        loading: true
      };
    case 'RESET_PASSWORD_EMAIL_FULFILLED':
      NotificationManager.success('Email sent successfully');
      action.meta && action.meta();
      return {
        ...state,
        loading: false,
        error: {}
      };
    case 'RESET_PASSWORD_EMAIL_REJECTED':
      NotificationManager.error('Email not found');
      return {
        ...state,
        error: action.payload.response.data,
        loading: false
      };
    case 'RESET_PASSWORD_PENDING':
      return {
        ...state,
        loading: true
      };
    case 'RESET_PASSWORD_FULFILLED':
      NotificationManager.success('Password reset successfully');
      action.meta && action.meta();
      return {
        ...state,
        loading: false,
        error: {}
      };
    case 'RESET_PASSWORD_REJECTED':
      NotificationManager.error('Invalid code');
      return {
        ...state,
        error: action.payload.response.data,
        loading: false
      };

    default:
      return state;
  }
}

export const register = (body, cb) => ({
  type: 'REGISTER',
  payload: company.register(body),
  meta: cb
});

export const login = (body, cb) => ({
  type: 'LOGIN',
  payload: company.login(body),
  meta: cb
});

export const reset_password_email = (body, cb) => ({
  type: 'RESET_PASSWORD_EMAIL',
  payload: company.reset_password_email(body),
  meta: cb
});

export const reset_password = (body, cb) => ({
  type: 'RESET_PASSWORD',
  payload: company.reset_password(body),
  meta: cb
});
