import React, { useState, Fragment } from 'react';
import {
  withStyles,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import moment from 'moment';
import { DateRangePicker } from 'react-dates';

const styles = theme => ({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit,
    outline: 'none',
    top: '50vh',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    minWidth: '30vw',
    minHeight: '30vh'
  },
  datepickers: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  mr: {
    marginRight: theme.spacing.unit * 2
  },
  ml: {
    marginLeft: theme.spacing.unit * 2
  }
});

const AccessLogsModal = props => {
  const { classes, selectedLock, stats, getStats } = props;
  const [startDate, setStartDate] = useState(moment().subtract(300, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  if (!stats[selectedLock._id])
    return <div className={classes.root}>Sorry, No statistics for this lock.</div>;

  const handleGetStats = () => {
    let body = {
      from: startDate.toISOString(),
      to: endDate.toISOString()
    };
    getStats(body, selectedLock._id);
  };

  let { avgTimeLocked, avgTimeUnlocked, locks, unlocks, other } = stats[selectedLock._id];

  const secondsToString = seconds => {
    seconds = Math.round(seconds / 1000);
    var numyears = Math.floor(seconds / 31536000);
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    let readable = '';

    if (numdays) readable += numdays + ' Days ';
    if (numhours) readable += numhours + ' Hours ';
    if (numminutes) readable += numminutes + ' Minutes ';
    if (numseconds) readable += numseconds + ' Seconds ';

    return readable;
  };

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Avg. Time Locked</TableCell>
            <TableCell>Avg. Time Unlocked</TableCell>
            <TableCell>Total Locks</TableCell>
            <TableCell>Total Unlocks</TableCell>
            <TableCell>Total Other commands</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{secondsToString(avgTimeLocked)}</TableCell>
            <TableCell>{secondsToString(avgTimeUnlocked)}</TableCell>
            <TableCell>{locks}</TableCell>
            <TableCell>{unlocks}</TableCell>
            <TableCell>{other}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <DateRangePicker
        startDate={startDate}
        startDateId="your_unique_start_date_id"
        endDate={endDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(moment(startDate));
          setEndDate(moment(endDate));
        }}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
      />
      <Button onClick={handleGetStats} color="primary" variant="contained">
        Get stats
      </Button>
      {stats && (
        <Fragment>
          <Typography>Unlocked: {stats.unlocked}</Typography>
          <Typography>Locked: {stats.locked}</Typography>
        </Fragment>
      )} */}
    </div>
  );
};

export default withStyles(styles)(AccessLogsModal);
