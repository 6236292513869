import { location } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  location: {},
  locations: [],
  loading: false,
  error: undefined,
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_LOCATION_PENDING':
      return { ...state, loading: true };
    case 'CREATE_LOCATION_FULFILLED':
      NotificationManager.success('Successfully Created Location');
      action.meta && action.meta();
      return { ...state, locations: [action.payload.data, ...state.locations], loading: false };
    case 'CREATE_LOCATION_REJECTED':
      NotificationManager.error('Location Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'EDIT_LOCATION_PENDING':
      return { ...state, loading: true };
    case 'EDIT_LOCATION_FULFILLED':
      NotificationManager.success('Successfully Edited Location');
      let _id = action.payload.data._id;
      let locations = [...state.locations];
      let index = locations.findIndex(location => location._id === _id);
      locations.splice(index, 1, action.payload.data);
      action.meta && action.meta();
      return { ...state, locations, loading: false };
    case 'EDIT_LOCATION_REJECTED':
      NotificationManager.error('Failed to edit location');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_LOCATIONS_PENDING':
      return { ...state, loading: true };
    case 'LIST_LOCATIONS_FULFILLED':
      return { ...state, locations: action.payload.data, did_get: true, loading: false };
    case 'LIST_LOCATIONS_REJECTED':
      return { ...state, loading: false };
    case 'DELETE_LOCATION_FULFILLED':
      _id = action.payload.data._id;
      index = state.locations.findIndex(location => location._id === _id);
      locations = [...state.locations];
      locations.splice(index, 1);
      NotificationManager.success('Successfully Deleted Location');
      action.meta && action.meta();
      return { ...state, locations };
    case 'DELETE_LOCATION_REJECTED':
      NotificationManager.error('An error occured');
      return state;
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_LOCATION',
  payload: location.create(body),
  meta: cb
});

export const edit = (body, id, cb) => ({
  type: 'EDIT_LOCATION',
  payload: location.edit(body, id),
  meta: cb
});

export const del = (id, cb) => ({
  type: 'DELETE_LOCATION',
  payload: location.delete({}, id),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_LOCATIONS',
  payload: location.list(body),
  meta: cb
});
