import React, { useState, useEffect } from 'react';
import { Modal, Dialog, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, edit, list, del } from '../../reducers/passReducer';
import moment from 'moment';
import Confirmation from '../../components/Confirmation/Confirmation';
import CreatePass from './CreatePass';
import debounce from '../../util/debounce';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const styles = theme => ({
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit / 2
  },
  modal: {
    overflow: 'scroll',
    width: '70vw',
    left: '15vw'
  },
  mobileModal: {
    width: '100vw',
    height: '100vh',
    overflow: 'scroll'
  }
});

const Passes = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPass, setSelectedPass] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { classes, passes, did_get, loading } = props;

  const modalClass = window.innerWidth < 950 ? classes.mobileModal : classes.modal;

  useEffect(() => {
    if (!did_get) props.list();
  });

  const onCreate = () => {
    setSelectedPass({});
    setModalOpen(true);
  };

  const del = () => {
    props.del(selectedPass._id, () => {
      setDeleteDialogOpen(false);
    });
  };

  const formatTime = time => {
    let hour = Math.floor(time / 60);
    let minute = time % 60;

    if (hour < 10) hour = '0' + hour;
    if (minute < 10) minute = '0' + minute;
    return hour + ':' + minute;
  };

  const onEdit = pass => {
    let continuous = false;
    const startTime = pass.weekdays[0] && pass.weekdays[0].start_time;
    const endTime = pass.weekdays[0] && pass.weekdays[0].end_time;
    if (startTime === 0 && endTime === 1439) continuous = true;
    setSelectedPass({
      selectedUsers: pass.users.map(user => ({ value: user._id, label: user.full_name })),
      selectedLocks: pass.locks.map(lock => ({
        value: lock._id,
        label: lock.name,
        location: lock.location._id
      })),
      selectedLocation: pass.location
        ? { value: pass.location._id, label: pass.location.name }
        : null,
      startDate: moment(pass.start_date),
      endDate: pass.end_date && moment(pass.end_date),
      continuous,
      days: pass.weekdays.map(day => ({ value: day.day, label: days[day.day] })),
      startTime: { value: startTime, label: formatTime(startTime) },
      endTime: { value: endTime, label: formatTime(endTime) },
      _id: pass._id,
      name: pass.name
    });
    setModalOpen(true);
  };

  const headers = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Start Date',
      selector: 'start_date',
      sortable: true,
      format: ({ start_date }) => moment(start_date).format('DD.MM.YYYY')
    },
    {
      name: 'End Date',
      selector: 'end_date',
      sortable: true,
      format: ({ end_date }) => {
        if (end_date) return moment(end_date).format('DD.MM.YYYY');
        return 'Continuous';
      }
    },
    {
      name: 'Location',
      selector: 'location.name',
      sortable: true
    },
    {
      name: 'User(s)',
      selector: 'users',
      compact: true,
      wrap: true,
      cell: ({ users }) => {
        return [
          users
            .slice(0, 2)
            .map(user => <Chip key={user._id} label={user.full_name} className={classes.chip} />),
          users.length > 2 ? (
            <Chip key="def" label={`and ${users.length - 2} more..`} className={classes.chip} />
          ) : null
        ];
      }
    },
    {
      name: 'Lock(s)',
      selector: 'locks',
      wrap: true,
      compact: true,
      format: ({ locks }) => {
        return [
          locks
            .slice(0, 2)
            .map(lock => <Chip key={lock._id} label={lock.name} className={classes.chip} />),
          locks.length > 2 ? (
            <Chip key="def" label={`and ${locks.length - 2} more..`} className={classes.chip} />
          ) : null
        ];
      }
    },
    {
      name: 'Status',
      selector: 'end_date',
      format: ({ start_date, end_date }) => {
        if (start_date && moment(start_date).isAfter(moment())) return 'Awaiting';
        if (end_date && moment(end_date).isBefore(moment())) return 'Expired';
        return 'Active';
      }
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ full_name: keyword });
    });
  };

  return (
    <div>
      <Modal className={modalClass} open={modalOpen} onClose={() => setModalOpen(false)}>
        <CreatePass selectedPass={selectedPass} closeModal={() => setModalOpen(false)} />
      </Modal>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to delete this pass?"
          description="This action will permanently delete pass"
          actions={[
            {
              text: 'Delete Permanently',
              color: 'secondary',
              onClick: () => del()
            },
            {
              text: 'Cancel',
              color: 'primary',
              onClick: () => setDeleteDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setDeleteDialogOpen(false)}
          agreeText="Delete Permanently"
          agreeAction={() => {}}
        />
      </Dialog>
      <Table
        loading={loading}
        data={passes}
        search={search}
        headers={headers}
        create={onCreate}
        edit={onEdit}
        delete={pass => {
          setSelectedPass(pass);
          setDeleteDialogOpen(true);
        }}
        title="Passes"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.passReducer.loading,
  passes: state.passReducer.passes,
  did_get: state.passReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      edit,
      del
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Passes)
);
