import React, { useState, useEffect } from 'react';
import { Modal, Dialog, Chip} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddEmployeeModal from './AddEmployeeModal';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, edit, list, del } from '../../reducers/employeeReducer';
import { list as listLocations } from '../../reducers/locationReducer';
import moment from 'moment';
import Confirmation from '../../components/Confirmation/Confirmation';
import debounce from '../../util/debounce';

const styles = theme => ({
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit / 2
  }
});

const Employees = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { employees, did_get, loading, did_get_locations, locations, classes } = props;

  if (!did_get_locations) props.listLocations();

  useEffect(() => {
    if (!did_get) props.list();
  });

  const create = employee => {
    props.create(employee, () => {
      setModalOpen(false);
    });
  };

  const edit = employee => {
    props.edit(employee, employee._id, () => {
      setModalOpen(false);
    });
  };

  const del = () => {
    props.del(selectedEmployee._id, () => {
      setDeleteDialogOpen(false);
    });
  };

  const onCreate = () => {
    setSelectedEmployee(null);
    setModalOpen(true);
  };

  const onDelete = employee => {
    setSelectedEmployee(employee);
    setDeleteDialogOpen(true);
  };

  const onEdit = employee => {
    setSelectedEmployee(employee);
    setModalOpen(true);
  };

  const headers = [
    {
      name: 'Full Name',
      selector: 'full_name',
      sortable: true
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Phone Number',
      selector: 'phone_number',
      sortable: true
    },
    {
      name: 'Location',
      selector: 'location.name',
      sortable: true
    },
    {
      name: 'Has Access',
      selector: 'hasAccess',
      sortable: true,
      format: data => (
        <Chip
          label={data.hasAccess ? 'Yes' : 'No'}
          className={classes.chip}
          color={data.hasAccess ? 'primary' : 'secondary'}
        />
      )
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ email: keyword, full_name: keyword });
    });
  };

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddEmployeeModal
          locations={locations}
          loading={loading}
          selectedEmployee={selectedEmployee}
          create={create}
          edit={edit}
        />
      </Modal>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to delete this employee?"
          description="This action will permanently delete employee, and all connected data including passes, statistics etc."
          actions={[
            {
              text: 'Delete Permanently',
              color: 'secondary',
              onClick: () => del()
            },
            {
              text: 'Cancel',
              color: 'primary',
              onClick: () => setDeleteDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setDeleteDialogOpen(false)}
          agreeText="Delete Permanently"
          agreeAction={() => {}}
        />
      </Dialog>
      <Table
        search={search}
        loading={loading}
        data={employees}
        headers={headers}
        create={onCreate}
        delete={onDelete}
        edit={onEdit}
        title="Employees"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.employeeReducer.loading,
  employees: state.employeeReducer.employees,
  locations: state.locationReducer.locations,
  did_get_locations: state.locationReducer.did_get,
  did_get: state.employeeReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      edit,
      del,
      listLocations
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Employees)
);
