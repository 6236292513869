import React from 'react';
import Download from '@axetroy/react-download';

import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 80,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  key: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing.unit
  }
});

const CardKeyModal = props => {
  const { classes, cardKey } = props;

  const element = document.createElement('hiddendiv');
  document.body.appendChild(element);

  return (
    <div className={classes.paper}>
      <Typography>Your new card key is created</Typography>
      <Typography className={classes.key}>{cardKey}</Typography>
      <Typography>Please write this key on card</Typography>
    </div>
  );
};

export default withStyles(styles)(CardKeyModal);
