import Geocode from 'react-geocode';
import { GOOGLE_MAPS_KEY } from '../../config';

Geocode.setApiKey(GOOGLE_MAPS_KEY);

export const geocodeAddress = address =>
  new Promise((resolve, reject) => {
    Geocode.fromAddress(address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        resolve({ lat, lng });
      },
      error => reject(error)
    );
  });
