import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  ExpansionPanel,
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Chip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  chip: {
    borderRadius: 5,
    margin: theme.spacing.unit
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  route: {
    fontWeight: 'bold',
    margin: theme.spacing.unit
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing.unit * 3
  },
  requestBody: {
    padding: '15px',
    fontFamily: 'Courier, sans- serif',
    fontSize: '13px',
    color: '#fff',
    backgroundColor: '#3d3d3d',
    marginBottom: theme.spacing.unit * 3
  },
  description: {
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit,
    backgroundColor: '#f5f5f5'
  },
  table: {
    marginBottom: theme.spacing.unit * 3
  },
  body: {
    padding: theme.spacing.unit * 3,
    width: '100%'
  }
});

const Endpoint = props => {
  const { endpoint, classes } = props;

  const createRequestBody = () => {
    let requestBody = {};
    endpoint.parameters.forEach(parameter => {
      requestBody[parameter.name] = parameter.exampleValue;
    });
    console.log(requestBody);
    return requestBody;
  };

  return (
    <div className="mt-3">
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.title}>
            <Chip label={endpoint.method} className={classes.chip} />
            <Typography className={classes.route}>{endpoint.route}</Typography>
            <Typography className={classes.heading}>{endpoint.name}</Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.body}>
            <Typography className={classes.subtitle}>Parameters</Typography>
            {endpoint.parameters.length > 0 ? (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Parameter</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {endpoint.parameters.map(parameter => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Typography>
                            {parameter.name}{' '}
                            {parameter.required && <span style={{ color: 'red' }}>* required</span>}
                          </Typography>
                        </TableCell>
                        <TableCell>{parameter.description}</TableCell>
                        <TableCell>{parameter.type}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Typography className={classes.description}>
                No additional parameters available
              </Typography>
            )}
            <Typography className={classes.subtitle}>Headers</Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Header</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {endpoint.headers.map(header => {
                  return (
                    <TableRow>
                      <TableCell>{header.name}</TableCell>
                      <TableCell>{header.value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {endpoint.parameters.length > 0 && [
              <Typography key={0} className={classes.subtitle}>
                Example request body
              </Typography>,
              <pre key={1} className={classes.requestBody}>
                {JSON.stringify(createRequestBody(), null, 2)}
              </pre>
            ]}
            <Typography className={classes.subtitle}>Response</Typography>
            <Typography className={classes.description}>
              Api will return array of locks that user can open.
            </Typography>
            <Typography className={classes.subtitle}>Example Response</Typography>
            <pre className={classes.requestBody}>{JSON.stringify(endpoint.response, null, 2)}</pre>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default withStyles(styles)(Endpoint);
