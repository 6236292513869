import React, { useState } from 'react';
import { Modal, Dialog, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddLocationModal from './AddLocationModal';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, edit, list, del } from '../../reducers/locationReducer';
import moment from 'moment';
import Confirmation from '../../components/Confirmation/Confirmation';
import debounce from '../../util/debounce';

const styles = theme => ({});

const Locations = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { locations, did_get, loading } = props;

  if (!did_get) props.list();

  const create = location => {
    props.create(location, () => {
      setModalOpen(false);
    });
  };

  const edit = location => {
    props.edit(location, location._id, () => {
      setModalOpen(false);
    });
  };

  const del = () => {
    props.del(selectedLocation._id, () => {
      setDeleteDialogOpen(false);
    });
  };

  const onCreate = () => {
    setSelectedLocation(null);
    setModalOpen(true);
  };

  const onDelete = location => {
    setSelectedLocation(location);
    setDeleteDialogOpen(true);
  };

  const onEdit = location => {
    setSelectedLocation(location);
    setModalOpen(true);
  };

  const headers = [
    {
      name: 'Location Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: data => moment(data.createdAt).format('DD.MM.YYYY')
    },
    {
      name: 'Address',
      selector: 'address',
      sortable: true
    },
    {
      name: 'Map',
      cell: row => (
        <Link component="button" variant="body2" onClick={() => {}}>
          Show on map
        </Link>
      )
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ name: keyword, address: keyword });
    });
  };

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddLocationModal
          loading={loading}
          create={create}
          edit={edit}
          selectedLocation={selectedLocation}
        />
      </Modal>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to delete this location?"
          description="This action will permanently delete location, and all connected data including passes, statistics etc."
          actions={[
            {
              text: 'Delete Permanently',
              color: 'secondary',
              onClick: () => del()
            },
            {
              text: 'Cancel',
              color: 'primary',
              onClick: () => setDeleteDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setDeleteDialogOpen(false)}
          agreeText="Delete Permanently"
          agreeAction={() => {}}
        />
      </Dialog>
      <Table
        loading={loading}
        data={locations}
        headers={headers}
        create={onCreate}
        delete={onDelete}
        search={search}
        edit={onEdit}
        title="Locations"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.locationReducer.loading,
  locations: state.locationReducer.locations,
  did_get: state.locationReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      edit,
      del
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Locations)
);
