import React, { useState, Fragment } from 'react';
import { set_password } from '../../reducers/userReducer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  CssBaseline,
  Paper,
  Typography,
  FormControl,
  Input,
  InputLabel,
  Button,
  CircularProgress
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from '../../Static/logo-rent24-light.jpg';
import useForm from '../../hooks/useForm';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  logo: {
    width: 200
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

function CreatePassword(props) {
  const { classes } = props;

  const [finished, setFinished] = useState(false);

  const rules = {
    password: {
      type: 'string',
      required: true,
      minLength: 6
    },
    repeatPassword: {
      type: 'string',
      required: true,
      minLength: 6
    }
  };

  const code = props.location.search && props.location.search.split('=')[1];

  const submit = () => {
    const { password, repeatPassword } = values;
    if (password !== repeatPassword) {
      errors.password = 'Passwords do not match';
      errors.repeatPassword = 'Passwords do not match';
      return;
    } else {
      props.set_password({ password, code }, () => {
        setFinished(true);
      });
      console.log({ password, code });
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    {
      password: '',
      repeatPassword: ''
    },
    submit,
    rules
  );

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img src={logo} className={classes.logo} alt="24 Solutions" />
        {props.loading ? (
          <CircularProgress size={24} className={classes.loader} />
        ) : finished ? (
          <Typography>
            You successfully changed your password. To start using our system, download our app from
            Google Play or App store.
          </Typography>
        ) : (
          <Fragment>
            <Typography component="h1" variant="h5">
              Create Your Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Enter Password</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  error={errors.password != null}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="repeatPassword">Repeat Password</InputLabel>
                <Input
                  name="repeatPassword"
                  type="password"
                  id="repeatPassword"
                  value={values.repeatPassword}
                  onChange={handleChange}
                  error={errors.repeatPassword != null}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {'Submit'}
              </Button>
            </form>
          </Fragment>
        )}
      </Paper>
    </main>
  );
}

function mapState(state) {
  return {
    loading: state.userReducer.loading
  };
}

function mapDispatch(dispatch) {
  return bindActionCreators({ set_password }, dispatch);
}

export default withRouter(
  withStyles(styles)(
    connect(
      mapState,
      mapDispatch
    )(CreatePassword)
  )
);
