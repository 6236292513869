import React, { useState } from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import useForm from '../../hooks/useForm';
import ModifiedMap from '../../components/Map/GoogleMap';
import { Marker } from 'react-google-maps';
import { geocodeAddress } from '../../components/Map/GeoCode';
import debounce from '../../util/debounce';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 80,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddLocationModal = props => {
  const { classes, selectedLocation, loading } = props;

  const id = selectedLocation && selectedLocation._id;

  const [mapCenter, setMapCenter] = useState(
    id
      ? {
          lat: selectedLocation.geolocation.coordinates[1],
          lng: selectedLocation.geolocation.coordinates[0]
        }
      : {
          lat: 52.49358,
          lng: 13.36061
        }
  );

  const [address, setAddress] = useState(id ? selectedLocation.address : '');

  const submit = () => {
    let submitValues = {
      name: values.name,
      address,
      coordinates: mapCenter,
      _id: id
    };
    id ? props.edit(submitValues) : props.create(submitValues);
  };

  const getCoordinates = async () => {
    try {
      const coordinates = await geocodeAddress(address);
      setMapCenter(coordinates);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultValues = selectedLocation || {
    name: ''
  };

  const rules = {
    name: {
      type: 'string',
      required: true
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(defaultValues, submit, rules);

  const onAddressChange = e => {
    e && e.preventDefault();
    setAddress(e.target.value);
    debounce(500, () => getCoordinates());
  };

  return (
    <div className={classes.paper}>
      <Typography variant="h5">{id ? 'Edit Location' : 'Create New Location'}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name">Location Name</InputLabel>
          <Input
            autoFocus
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="address">Address</InputLabel>
          <Input id="address" name="address" value={address} onChange={onAddressChange} />
        </FormControl>
        <ModifiedMap
          center={mapCenter}
          autocomplete
          setMapCenter={addr => setMapCenter(addr)}
          changeLocation={addr => setAddress(addr)}
          location={address}
          containerElement={<div style={{ height: '400px', width: '100%' }} />}
          zoom={17}
        >
          <Marker
            position={mapCenter}
            draggable
            onDragEnd={e => setMapCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
          />
        </ModifiedMap>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {id ? 'Edit' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddLocationModal);
