import React, { useState } from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import useForm from '../../hooks/useForm';
import Select from 'react-select';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddCardModal = props => {
  const { classes, selectedCard, loading } = props;
  const users = props.users.map(user => ({ value: user._id, label: user.full_name }));
  const id = selectedCard && selectedCard._id;

  const submit = () => {
    if (values.holder) values.holder = values.holder.value;
    return id ? props.edit(values) : props.create(values);
  };

  const defaultValues = selectedCard || {
    name: '',
    holder: { label: 'Card Holder Not Selected', value: -1 }
  };

  const rules = {
    name: {
      type: 'string',
      required: true
    },
    holder: {
      type: 'string'
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(defaultValues, submit, rules);

  const handleHolderChange = holder => {
    handleChange('holder', holder);
  };

  return (
    <div className={classes.paper}>
      <Typography variant="h5">{id ? 'Edit Card' : 'Create New Card'}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name">Card Name</InputLabel>
          <Input
            autoFocus
            id="name"
            name="name"
            autoComplete="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
        </FormControl>
        <Select
          className="mt-3"
          value={values.holder}
          onChange={handleHolderChange}
          options={users}
          placeholder="Select Card Holder"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {id ? 'Edit' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddCardModal);
