import { combineReducers } from 'redux';
import userReducer from './userReducer';
import authReducer from './authReducer';
import locationReducer from './locationReducer';
import lockReducer from './lockReducer';
import passReducer from './passReducer';
import gatewayReducer from './gatewayReducer';
import apiKeyReducer from './apiKeyReducer';
import cardReducer from './cardReducer';
import employeeReducer from './employeeReducer';

export default combineReducers({
  userReducer,
  authReducer,
  locationReducer,
  lockReducer,
  passReducer,
  gatewayReducer,
  apiKeyReducer,
  cardReducer,
  employeeReducer
});
