import {
  HomeOutlined,
  PersonOutline,
  PeopleOutline,
  PinDropOutlined,
  LockOutlined,
  VpnKeyOutlined,
  CreditCardOutlined,
  SettingsEthernetOutlined
} from '@material-ui/icons';

const Routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: HomeOutlined
  },
  {
    path: '/dashboard/employees',
    name: 'Employees',
    icon: PeopleOutline
  },
  {
    path: '/dashboard/users',
    name: 'Users',
    icon: PersonOutline
  },
  {
    path: '/dashboard/locations',
    name: 'Locations',
    icon: PinDropOutlined
  },
  {
    path: '/dashboard/locks',
    name: 'Locks',
    icon: LockOutlined
  },
  {
    path: '/dashboard/passes',
    name: 'Passes',
    icon: VpnKeyOutlined
  },
  {
    path: '/dashboard/cards',
    name: 'Cards',
    icon: CreditCardOutlined
  },
  {
    path: '/dashboard/gateways',
    name: 'Gateways',
    icon: SettingsEthernetOutlined
  },
  { redirect: true, path: '/', pathTo: '/dashboard', name: 'Dashboard' }
];
export default Routes;
