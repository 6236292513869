import { lock } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  lock: {},
  locks: [],
  loading: false,
  error: undefined,
  stats: {},
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_LOCK_PENDING':
      return { ...state, loading: true };
    case 'CREATE_LOCK_FULFILLED':
      NotificationManager.success('Successfully Created Lock');
      action.meta && action.meta();
      return { ...state, locks: [action.payload.data, ...state.locks], loading: false };
    case 'CREATE_LOCK_REJECTED':
      NotificationManager.error('Lock Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'EDIT_LOCK_PENDING':
      return { ...state, loading: true };
    case 'EDIT_LOCK_FULFILLED':
      NotificationManager.success('Successfully Edited Lock');
      let _id = action.payload.data._id;
      let locks = [...state.locks];
      let index = locks.findIndex(lock => lock._id === _id);
      locks.splice(index, 1, action.payload.data);
      action.meta && action.meta();
      return { ...state, locks: [...locks], loading: false };
    case 'EDIT_LOCK_REJECTED':
      NotificationManager.error('Failed to edit lock');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_LOCKS_PENDING':
      return { ...state, loading: true };
    case 'LIST_LOCKS_FULFILLED':
      return { ...state, locks: action.payload.data, did_get: true, loading: false };
    case 'LIST_LOCKS_REJECTED':
      return { ...state, loading: false };
    case 'DELETE_LOCK_FULFILLED':
      _id = action.payload.data._id;
      index = state.locks.findIndex(lock => lock._id === _id);
      locks = [...state.locks];
      locks.splice(index, 1);
      NotificationManager.success('Successfully Deleted Lock');
      action.meta && action.meta();
      return { ...state, locks, loading: false };
    case 'DELETE_LOCK_REJECTED':
      NotificationManager.error('An error occured');
      return { ...state, loading: false };
    case 'SEND_COMMAND_PENDING':
      return { ...state, loading: true };
    case 'SEND_COMMAND_FULFILLED':
      NotificationManager.success('Successfully Sent Command');
      action.meta && action.meta();
      return { ...state, loading: false };
    case 'SEND_COMMAND_REJECTED':
      NotificationManager.error('Unknown Error');
      return { ...state, loading: false };
    case 'LIST_LOGS_PENDING':
      return { ...state, loading: true };
    case 'LIST_LOGS_FULFILLED':
      let logs = action.payload.data;
      locks = [...state.locks];
      if (logs.length > 0) {
        _id = logs[0].lock;
        index = locks.findIndex(lock => lock._id === _id);
        if (index !== -1) {
          locks[index].logs = logs;
          action.meta && action.meta(locks[index]);
        }
      }
      return { ...state, locks, loading: false };
    case 'LIST_LOGS_REJECTED':
      NotificationManager.error('Network Error');
      return { ...state, loading: false };
    case 'GET_STATS_PENDING':
      return { ...state, loading: true };
    case 'GET_STATS_FULFILLED':
      let stats = action.payload.data;
      return { ...state, stats, loading: false };
    case 'GET_STATS_REJECTED':
      NotificationManager.error('Network Error');
      return { ...state, loading: false };
    case 'SET_LOCK':
      let lock = action.payload;
      locks = [...state.locks];
      console.log(lock);

      index = locks.findIndex(l => l._id === lock._id);
      if (index >= 0) {
        lock.location = locks[index].location;
        locks.splice(index, 1, lock);
      }
      return { ...state, locks };
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_LOCK',
  payload: lock.create(body),
  meta: cb
});

export const edit = (body, id, cb) => ({
  type: 'EDIT_LOCK',
  payload: lock.edit(body, id),
  meta: cb
});

export const del = (id, cb) => ({
  type: 'DELETE_LOCK',
  payload: lock.delete({}, id),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_LOCKS',
  payload: lock.list(body),
  meta: cb
});

export const command = (body, id, cb) => ({
  type: 'SEND_COMMAND',
  payload: lock.command(body, id),
  meta: cb
});

export const list_logs = (body, id, cb) => ({
  type: 'LIST_LOGS',
  payload: lock.list_logs(body, id),
  meta: cb
});

export const get_stats = (body, id, cb) => ({
  type: 'GET_STATS',
  payload: lock.stats(body, id),
  meta: cb
});

export const set_lock = lock => ({
  type: 'SET_LOCK',
  payload: lock
});
