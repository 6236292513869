import { gateway } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  gateways: [],
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_GATEWAYS_FULFILLED':
      return { ...state, gateways: action.payload.data, did_get: true };
    case 'LIST_GATEWAYS_REJECTED':
      return state;
    case 'ADD_PACKAGE_PENDING':
      return { ...state, loading: true };
    case 'ADD_PACKAGE_FULFILLED':
      NotificationManager.success('Package Added Successfully');
      action.meta && action.meta();
      return { ...state, loading: false, did_get: false };
    case 'ADD_PACKAGE_REJECTED':
      NotificationManager.error('Package not found!');
      return { ...state, loading: false };
    case 'EDIT_GATEWAY_PENDING':
      return { ...state, loading: true };
    case 'EDIT_GATEWAY_FULFILLED':
      NotificationManager.success('Successfully Edited Gateway');
      let _id = action.payload.data._id;
      let gateways = [...state.gateways];
      let index = gateways.findIndex(gateway => gateway._id === _id);
      gateways.splice(index, 1, action.payload.data);
      action.meta && action.meta();
      return { ...state, gateways: [...gateways], loading: false };
    case 'EDIT_GATEWAY_REJECTED':
      NotificationManager.error('Failed to edit gateway');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const list = (body, cb) => ({
  type: 'LIST_GATEWAYS',
  payload: gateway.list(body),
  meta: cb
});

export const edit = (body, id, cb) => ({
  type: 'EDIT_GATEWAY',
  payload: gateway.edit(body, id),
  meta: cb
});

export const add_package = (body, cb) => ({
  type: 'ADD_PACKAGE',
  payload: gateway.add_package(body),
  meta: cb
});
