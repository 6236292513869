import { pass } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  pass: {},
  passes: [],
  loading: false,
  error: undefined,
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_PASS_PENDING':
      return { ...state, loading: true };
    case 'CREATE_PASS_FULFILLED':
      NotificationManager.success('Successfully Created Pass');
      action.meta && action.meta();
      return { ...state, passes: [action.payload.data, ...state.passes], loading: false };
    case 'CREATE_PASS_REJECTED':
      NotificationManager.error('Pass Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_PASSES_PENDING':
      return { ...state, loading: true };
    case 'LIST_PASSES_FULFILLED':
      return { ...state, passes: action.payload.data, did_get: true, loading: false };
    case 'LIST_PASSES_REJECTED':
      return { ...state, loading: false };
    case 'DELETE_PASS_FULFILLED':
      let _id = action.payload.data._id;
      let index = state.passes.findIndex(pass => pass._id === _id);
      let passes = [...state.passes];
      passes.splice(index, 1);
      NotificationManager.success('Successfully Deleted Pass');
      action.meta && action.meta();
      return { ...state, passes, loading: false };
    case 'DELETE_PASS_REJECTED':
      NotificationManager.error('An error occured');
      return { ...state, loading: false };
    case 'EDIT_PASS_PENDING':
      return { ...state, loading: true };
    case 'EDIT_PASS_FULFILLED':
      NotificationManager.success('Successfully Edited Pass');
      _id = action.payload.data._id;
      passes = [...state.passes];
      index = passes.findIndex(pass => pass._id === _id);
      passes.splice(index, 1, action.payload.data);
      action.meta && action.meta();
      return { ...state, passes: [...passes], loading: false };
    case 'EDIT_PASS_REJECTED':
      NotificationManager.error('Failed to edit pass');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_PASS',
  payload: pass.create(body),
  meta: cb
});

export const edit = (body, id, cb) => ({
  type: 'EDIT_PASS',
  payload: pass.edit(body, id),
  meta: cb
});

export const del = (id, cb) => ({
  type: 'DELETE_PASS',
  payload: pass.delete({}, id),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_PASSES',
  payload: pass.list(body),
  meta: cb
});
