import { employee } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  employee: {},
  employees: [],
  loading: false,
  error: undefined,
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_EMPLOYEE_PENDING':
      return { ...state, loading: true };
    case 'CREATE_EMPLOYEE_FULFILLED':
      NotificationManager.success('Successfully Created Employee');
      action.meta && action.meta();
      return { ...state, employees: [action.payload.data, ...state.employees], loading: false };
    case 'CREATE_EMPLOYEE_REJECTED':
      NotificationManager.error('Employee Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'EDIT_EMPLOYEE_PENDING':
      return { ...state, loading: true };
    case 'EDIT_EMPLOYEE_FULFILLED':
      NotificationManager.success('Successfully Edited Employee');
      let _id = action.payload.data._id;
      let employees = [...state.employees];
      let index = employees.findIndex(employee => employee._id === _id);
      employees.splice(index, 1, action.payload.data);
      action.meta && action.meta();
      return { ...state, employees: [...employees], loading: false };
    case 'EDIT_EMPLOYEE_REJECTED':
      NotificationManager.error('Failed to edit employee');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_EMPLOYEES_PENDING':
      return { ...state, loading: true };
    case 'LIST_EMPLOYEES_FULFILLED':
      return { ...state, employees: action.payload.data, did_get: true, loading: false };
    case 'LIST_EMPLOYEES_REJECTED':
      NotificationManager.error('Network error');
      return { ...state, loading: false };
    case 'DELETE_EMPLOYEE_FULFILLED':
      _id = action.payload.data._id;
      index = state.employees.findIndex(employee => employee._id === _id);
      employees = [...state.employees];
      employees.splice(index, 1);
      NotificationManager.success('Successfully Deleted Employee');
      action.meta && action.meta();
      return { ...state, employees };
    case 'DELETE_EMPLOYEE_REJECTED':
      NotificationManager.error('An error occured');
      return state;
    case 'SET_PASSWORD_PENDING':
      return {
        ...state,
        loading: true
      };
    case 'SET_PASSWORD_FULFILLED':
      action.meta && action.meta();
      return {
        ...state,
        loading: false
      };
    case 'SET_PASSWORD_REJECTED':
      NotificationManager.error('An error occured');
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_EMPLOYEE',
  payload: employee.create(body),
  meta: cb
});

export const edit = (body, id, cb) => ({
  type: 'EDIT_EMPLOYEE',
  payload: employee.edit(body, id),
  meta: cb
});

export const del = (id, cb) => ({
  type: 'DELETE_EMPLOYEE',
  payload: employee.delete({}, id),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_EMPLOYEES',
  payload: employee.list(body),
  meta: cb
});

export const set_password = (body, cb) => ({
  type: 'SET_PASSWORD',
  payload: employee.set_password(body),
  meta: cb
});
