import React from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import useForm from '../../hooks/useForm';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddGatewayModal = props => {
  const { classes, selectedGateway, loading } = props;

  const id = selectedGateway && selectedGateway._id;

  const submit = () => {
    return id ? props.edit(values) : props.create(values);
  };

  const defaultValues = selectedGateway || {
    name: '',
    port: ''
  };

  const rules = {
    name: {
      type: 'string'
    },
    serial: {
      type: 'string'
    },
    port: {
      type: 'number'
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(defaultValues, submit, rules);

  return (
    <div className={classes.paper}>
      <Typography variant="h5">{id ? 'Edit Gateway' : 'Create New Gateway'}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">Name</InputLabel>
          <Input
            autoFocus
            id="name"
            name="name"
            autoComplete="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="serial">Serial</InputLabel>
          <Input
            autoFocus
            id="serial"
            name="serial"
            autoComplete="serial"
            value={values.serial}
            onChange={handleChange}
            error={errors.serial}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="port">Port</InputLabel>
          <Input
            id="port"
            name="port"
            autoComplete="port"
            type="number"
            value={values.port}
            onChange={handleChange}
            error={errors.port}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {id ? 'Edit' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddGatewayModal);
