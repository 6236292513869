import React, { useState, useEffect } from 'react';
import { Modal, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddUserModal from './AddUserModal';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, edit, list, del } from '../../reducers/userReducer';
import moment from 'moment';
import Confirmation from '../../components/Confirmation/Confirmation';
import debounce from '../../util/debounce';

const styles = theme => ({});

const Users = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { users, did_get, loading } = props;

  useEffect(() => {
    if (!did_get) props.list();
  });

  const create = user => {
    props.create(user, () => {
      setModalOpen(false);
    });
  };

  const edit = user => {
    props.edit(user, user._id, () => {
      setModalOpen(false);
    });
  };

  const del = () => {
    props.del(selectedUser._id, () => {
      setDeleteDialogOpen(false);
    });
  };

  const onCreate = () => {
    setSelectedUser(null);
    setModalOpen(true);
  };

  const onDelete = user => {
    setSelectedUser(user);
    setDeleteDialogOpen(true);
  };

  const onEdit = user => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const headers = [
    {
      name: 'Full Name',
      selector: 'full_name',
      sortable: true
    },
    {
      name: 'User Since',
      selector: 'createdAt',
      sortable: true,
      format: ({ createdAt }) => moment(createdAt).format('DD.MM.YYYY')
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Account Id',
      selector: 'account_id',
      sortable: true
    },
    {
      name: 'Contact Id',
      selector: 'contact_id',
      sortable: true
    },
    {
      name: 'Phone Number',
      selector: 'phone_number',
      sortable: true
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ email: keyword, full_name: keyword });
    });
  };

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddUserModal loading={loading} selectedUser={selectedUser} create={create} edit={edit} />
      </Modal>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to delete this user?"
          description="This action will permanently delete user, and all connected data including passes, statistics etc."
          actions={[
            {
              text: 'Delete Permanently',
              color: 'secondary',
              onClick: () => del()
            },
            {
              text: 'Cancel',
              color: 'primary',
              onClick: () => setDeleteDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setDeleteDialogOpen(false)}
          agreeText="Delete Permanently"
          agreeAction={() => { }}
        />
      </Dialog>
      <Table
        search={search}
        loading={loading}
        data={users}
        headers={headers}
        create={onCreate}
        delete={onDelete}
        edit={onEdit}
        title="Users"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.userReducer.loading,
  users: state.userReducer.users,
  did_get: state.userReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      edit,
      del
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Users)
);
