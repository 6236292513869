import React, { useState, useEffect } from 'react';
import { Modal, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddCardModal from './AddCardModal';
import CardKeyModal from './CardKeyModal';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, edit, list, del } from '../../reducers/cardReducer';
import { list as listUsers } from '../../reducers/userReducer';
import moment from 'moment';
import Confirmation from '../../components/Confirmation/Confirmation';
import debounce from '../../util/debounce';

const styles = theme => ({});

const Cards = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cardKey, setCardKey] = useState(false);
  const [cardKeyModalOpen, setCardKeyModalOpen] = useState(false);
  const { cards, did_get, loading, did_get_users, users } = props;

  useEffect(() => {
    if (!did_get_users) props.listUsers();
    if (!did_get) props.list();
  });

  const create = card => {
    props.create(card, key => {
      setModalOpen(false);
      setCardKey(key);
      setCardKeyModalOpen(true);
    });
  };

  const edit = card => {
    props.edit(card, card._id, () => {
      setModalOpen(false);
    });
  };

  const del = () => {
    props.del(selectedCard._id, () => {
      setDeleteDialogOpen(false);
    });
  };

  const onCreate = () => {
    setSelectedCard(null);
    setModalOpen(true);
  };

  const onDelete = card => {
    setSelectedCard(card);
    setDeleteDialogOpen(true);
  };

  const onEdit = card => {
    setSelectedCard(card);
    setModalOpen(true);
  };

  const headers = [
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: ({ createdAt }) => moment(createdAt).format('DD.MM.YYYY')
    },
    {
      name: 'Card Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Cardholder',
      selector: 'holder.full_name',
      sortable: true
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ name: keyword });
    });
  };

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddCardModal
          loading={loading}
          selectedCard={selectedCard}
          users={users}
          create={create}
          edit={edit}
        />
      </Modal>
      <Modal open={cardKeyModalOpen} onClose={() => setCardKeyModalOpen(false)}>
        <CardKeyModal cardKey={cardKey} />
      </Modal>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to delete this card?"
          description="This action will delete card from system, and all access will be denied"
          actions={[
            {
              text: 'Delete Permanently',
              color: 'secondary',
              onClick: () => del()
            },
            {
              text: 'Cancel',
              color: 'primary',
              onClick: () => setDeleteDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setDeleteDialogOpen(false)}
          agreeText="Delete Permanently"
          agreeAction={() => {}}
        />
      </Dialog>
      <Table
        addDisabled={true}
        search={search}
        loading={loading}
        data={cards}
        headers={headers}
        create={onCreate}
        delete={onDelete}
        edit={onEdit}
        title="Cards"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.cardReducer.loading,
  cards: state.cardReducer.cards,
  did_get: state.cardReducer.did_get,
  users: state.userReducer.users,
  did_get_users: state.userReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      edit,
      del,
      listUsers
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Cards)
);
