import React from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import useForm from '../../../hooks/useForm';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddApiKeyModal = props => {
  const { classes, selectedApiKey, loading } = props;
  const id = selectedApiKey && selectedApiKey._id;

  const submit = () => {
    return id ? props.edit(values) : props.create(values);
  };

  const defaultValues = selectedApiKey || {
    name: ''
  };

  const rules = {
    name: {
      type: 'string',
      required: true
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(defaultValues, submit, rules);

  return (
    <div className={classes.paper}>
      <Typography variant="h5">{id ? 'Edit ApiKey' : 'Create New ApiKey'}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name">Name</InputLabel>
          <Input
            autoFocus
            id="name"
            name="name"
            autoComplete="name"
            placeholder="Ex. (Project 1)"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {id ? 'Edit' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddApiKeyModal);
