import React from 'react';
import Download from '@axetroy/react-download';

import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 80,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  key: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing.unit
  }
});

const KeyCreatedModal = props => {
  const { classes, apiKey } = props;

  const element = document.createElement('hiddendiv');
  document.body.appendChild(element);

  return (
    <div className={classes.paper}>
      <Typography>
        Your new api key is created and <b>it will be displayed only now</b>
      </Typography>
      <Typography className={classes.key}>{apiKey.key}</Typography>
      <Typography>
        Please store it somewhere safe because as soon as you navigate away from this page, we will
        not be able to retreive or restore this generated key.
      </Typography>
      <Typography>
        We also recommend that you download api key file and store it somewhere safe.
      </Typography>
      <Download
        file={`api_key_${apiKey._id}.json`}
        content={JSON.stringify({ public_id: apiKey._id, api_key: apiKey.key })}
      >
        <Button color="primary">Download</Button>
      </Download>
    </div>
  );
};

export default withStyles(styles)(KeyCreatedModal);
