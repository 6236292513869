import axios from 'axios';
import { BASE_URL } from './config';

const defaultHeaders = () => ({
  Authorization: 'Bearer ' + localStorage.getItem('token')
});

export const get = (url, params = {}, headers = defaultHeaders()) =>
  axios
    .get(BASE_URL + url, { params, headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const post = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .post(BASE_URL + url, body, { headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const put = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .put(BASE_URL + url, body, { headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const patch = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .patch(BASE_URL + url, body, { headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const del = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .delete(BASE_URL + url, { body, headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const user = {
  create: body => post('user', body),
  edit: (body, id) => patch(`user/${id}`, body),
  list: body => get('user', body),
  delete: (body, id) => del(`user/${id}`, body),
  set_password: body => post(`user/set_password`, body)
};

export const employee = {
  create: body => post('employee', body),
  edit: (body, id) => patch(`employee/${id}`, body),
  list: body => get('employee', body),
  delete: (body, id) => del(`employee/${id}`, body)
  // set_password: body => post(`employee/set_password`, body)
};

export const company = {
  register: body => post('company', body),
  login: body => post('company/login', body),
  reset_password_email: body => post('company/reset_password_email', body),
  reset_password: body => post('company/reset_password', body)
};

export const location = {
  create: body => post('location', body),
  edit: (body, id) => patch(`location/${id}`, body),
  list: body => get('location', body),
  delete: (body, id) => del(`location/${id}`, body)
};

export const lock = {
  create: body => post('lock', body),
  edit: (body, id) => patch(`lock/${id}`, body),
  list: body => get('lock', body),
  delete: (body, id) => del(`lock/${id}`, body),
  command: (body, id) => post(`lock/${id}/command`, body),
  list_logs: (body, id) => get(`lock/${id}/logs`, body),
  stats: (body, id) => get(`lock/${id}/stats`, body)
};

export const pass = {
  create: body => post('pass', body),
  edit: (body, id) => patch(`pass/${id}`, body),
  list: body => get('pass', body),
  delete: (body, id) => del(`pass/${id}`, body)
};

export const gateway = {
  list: body => get('gateway', body),
  edit: (body, id) => patch(`gateway/${id}`, body),
  add_package: body => post(`gateway`, body)
};

export const apiKey = {
  create: body => post('apikey', body),
  list: body => get('apikey', body),
  delete: (body, id) => del(`apikey/${id}`, body)
};

export const card = {
  create: body => post('card', body),
  edit: (body, id) => patch(`card/${id}`, body),
  list: body => get('card', body),
  delete: (body, id) => del(`card/${id}`, body)
};
