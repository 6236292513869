import { user } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  user: {},
  users: [],
  loading: false,
  error: undefined,
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_USER_PENDING':
      return { ...state, loading: true };
    case 'CREATE_USER_FULFILLED':
      NotificationManager.success('Successfully Created User');
      action.meta && action.meta();
      return { ...state, users: [action.payload.data, ...state.users], loading: false };
    case 'CREATE_USER_REJECTED':
      NotificationManager.error('User Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'EDIT_USER_PENDING':
      return { ...state, loading: true };
    case 'EDIT_USER_FULFILLED':
      NotificationManager.success('Successfully Edited User');
      let _id = action.payload.data._id;
      let users = [...state.users];
      let index = users.findIndex(user => user._id === _id);
      users.splice(index, 1, action.payload.data);
      action.meta && action.meta();
      return { ...state, users: [...users], loading: false };
    case 'EDIT_USER_REJECTED':
      NotificationManager.error('Failed to edit user');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_USERS_PENDING':
      return { ...state, loading: true };
    case 'LIST_USERS_FULFILLED':
      return { ...state, users: action.payload.data, did_get: true, loading: false };
    case 'LIST_USERS_REJECTED':
      NotificationManager.error('Network error');
      return { ...state, loading: false };
    case 'DELETE_USER_FULFILLED':
      _id = action.payload.data._id;
      index = state.users.findIndex(user => user._id === _id);
      users = [...state.users];
      users.splice(index, 1);
      NotificationManager.success('Successfully Deleted User');
      action.meta && action.meta();
      return { ...state, users };
    case 'DELETE_USER_REJECTED':
      NotificationManager.error('An error occured');
      return state;
    case 'SET_PASSWORD_PENDING':
      return {
        ...state,
        loading: true
      };
    case 'SET_PASSWORD_FULFILLED':
      action.meta && action.meta();
      return {
        ...state,
        loading: false
      };
    case 'SET_PASSWORD_REJECTED':
      NotificationManager.error('An error occured');
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_USER',
  payload: user.create(body),
  meta: cb
});

export const edit = (body, id, cb) => ({
  type: 'EDIT_USER',
  payload: user.edit(body, id),
  meta: cb
});

export const del = (id, cb) => ({
  type: 'DELETE_USER',
  payload: user.delete({}, id),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_USERS',
  payload: user.list(body),
  meta: cb
});

export const set_password = (body, cb) => ({
  type: 'SET_PASSWORD',
  payload: user.set_password(body),
  meta: cb
});
