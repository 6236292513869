import React, { useState } from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  withStyles,
  MenuItem,
  Select,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';

import useForm from '../../hooks/useForm';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 80,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddLockModal = props => {
  const { classes, selectedLock, loading } = props;
  const [autoLockEnabled, setAutoLockEnabled] = useState(
    props.selectedLock ? props.selectedLock.lock_in !== 0 : false
  );
  const id = selectedLock && selectedLock._id;

  const submit = () => {
    if (!autoLockEnabled) {
      values.lock_in = 0;
      values.lock_in_type = 's';
    }
    id ? props.edit(values) : props.create(values);
  };

  const default_values = id
    ? { ...selectedLock, location: selectedLock.location && selectedLock.location._id }
    : {
        gateway: props.gateways && props.gateways[0] ? props.gateways[0]._id : '',
        name: '',
        MAC: '',
        floor: 0,
        location: '',
        lock_in: 1,
        lock_in_type: 's'
      };

  const rules = {
    name: {
      type: 'string',
      required: true
    },
    MAC: {
      type: 'mac_address',
      required: true
    },
    floor: {
      type: 'number',
      required: true
    },
    location: {
      type: 'string',
      required: true
    },
    gateway: {
      type: 'string',
      required: true
    },
    lock_in: {
      type: 'number',
      required: true
    },
    lock_in_type: {
      type: 'string',
      required: true
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(default_values, submit, rules);

  const handleMacChange = e => {
    e && e.persist();

    let mac = e.target.value.split(':').join('');

    if (mac && mac.length > 0) {
      mac = mac
        .match(/.{1,2}/g)
        .join(':')
        .substring(0, 17);
      return handleChange('MAC', mac);
    } else {
      return handleChange(e);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography variant="h5">{id ? 'Edit Lock' : 'Create New Lock'}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name">Lock Name</InputLabel>
          <Input
            autoFocus
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name">Mac Address</InputLabel>
          <Input
            id="MAC"
            name="MAC"
            value={values.MAC}
            onChange={handleMacChange}
            error={errors.MAC}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="gateway">Gateway</InputLabel>
          <Select
            id="gateway"
            name="gateway"
            value={values.gateway}
            onChange={e => handleChange(e)}
            error={errors.gateway}
            inputProps={{
              name: 'gateway',
              id: 'gateway'
            }}
          >
            {props.gateways.map(gateway => (
              <MenuItem key={gateway._id} value={gateway._id}>
                {gateway.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="location">Location</InputLabel>
          <Select
            id="location"
            name="location"
            value={values.location}
            onChange={e => handleChange(e)}
            error={errors.location}
            inputProps={{
              name: 'location',
              id: 'location'
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {props.locations.map(location => (
              <MenuItem key={location._id} value={location._id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="floor">Floor</InputLabel>
          <Input
            type="number"
            id="floor"
            name="floor"
            value={values.floor}
            onChange={handleChange}
            error={errors.floor}
          />
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color={'primary'}
                checked={autoLockEnabled}
                onChange={() => setAutoLockEnabled(!autoLockEnabled)}
              />
            }
            label="Enable Auto Lock"
          />
        </FormGroup>
        <FormControl margin="normal" className="mt-0 " disabled={!autoLockEnabled}>
          <InputLabel htmlFor="lock_in">Auto Lock After</InputLabel>
          <Input
            type="number"
            id="lock_in"
            name="lock_in"
            placeholder="0"
            value={values.lock_in}
            onChange={handleChange}
            error={errors.lock_in}
            className="mr-3"
            inputProps={{ min: 1 }}
          />
        </FormControl>
        <FormControl margin="normal" className="mt-0 " disabled={!autoLockEnabled}>
          <InputLabel htmlFor="lock_in_type"></InputLabel>
          <Select
            value={values.lock_in_type}
            onChange={e => handleChange(e)}
            name="lock_in_type"
            id="lock_in_type"
            error={errors.lock_in_type}
            inputProps={{
              name: 'lock_in_type',
              id: 'lock_in_type'
            }}
          >
            <MenuItem value={'s'}>Seconds</MenuItem>
            <MenuItem value={'m'}>Minutes</MenuItem>
            <MenuItem value={'h'}>Hours</MenuItem>
          </Select>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {id ? 'Edit' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddLockModal);
