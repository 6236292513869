import React from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import useForm from '../../hooks/useForm';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddUserModal = props => {
  const { classes, selectedUser, loading } = props;

  const id = selectedUser && selectedUser._id;

  const submit = () => {
    return id ? props.edit(values) : props.create(values);
  };

  const defaultValues = selectedUser || {
    full_name: '',
    email: '',
    phone_number: ''
  };

  const rules = {
    full_name: {
      type: 'string',
      required: true
    },
    email: {
      type: 'email',
      required: true
    },
    phone_number: {
      type: 'string'
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(defaultValues, submit, rules);

  return (
    <div className={classes.paper}>
      <Typography variant="h5">{id ? 'Edit User' : 'Create New User'}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="full_name">Full Name</InputLabel>
          <Input
            autoFocus
            id="full_name"
            name="full_name"
            autoComplete="full_name"
            value={values.full_name}
            onChange={handleChange}
            error={errors.full_name}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="phone_number">Phone Number</InputLabel>
          <Input
            name="phone_number"
            type="phone_number"
            id="phone_number"
            value={values.phone_number}
            onChange={handleChange}
            error={errors.phone_number}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="account_id">Account ID</InputLabel>
          <Input
            name="account_id"
            id="account_id"
            value={values.account_id}
            onChange={handleChange}
            error={errors.account_id}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="contact_id">Contact ID</InputLabel>
          <Input
            name="contact_id"
            id="contact_id"
            value={values.contact_id}
            onChange={handleChange}
            error={errors.contact_id}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {id ? 'Edit' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddUserModal);
