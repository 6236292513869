import { card } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  card: {},
  cards: [],
  loading: false,
  error: undefined,
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_CARD_PENDING':
      return { ...state, loading: true };
    case 'CREATE_CARD_FULFILLED':
      NotificationManager.success('Successfully Created Card');
      let card = action.payload.data;
      action.meta && action.meta(card.id_18);
      return { ...state, cards: [action.payload.data, ...state.cards], loading: false };
    case 'CREATE_CARD_REJECTED':
      NotificationManager.error('Card Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'EDIT_CARD_PENDING':
      return { ...state, loading: true };
    case 'EDIT_CARD_FULFILLED':
      NotificationManager.success('Successfully Edited Card');
      let _id = action.payload.data._id;
      let cards = [...state.cards];
      let index = cards.findIndex(card => card._id === _id);
      cards.splice(index, 1, action.payload.data);
      action.meta && action.meta();
      return { ...state, cards: [...cards], loading: false };
    case 'EDIT_CARD_REJECTED':
      NotificationManager.error('Failed to edit card');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_CARDS_PENDING':
      return { ...state, loading: true };
    case 'LIST_CARDS_FULFILLED':
      return { ...state, cards: action.payload.data, did_get: true, loading: false };
    case 'LIST_CARDS_REJECTED':
      NotificationManager.error('Network error');
      return { ...state, loading: false };
    case 'DELETE_CARD_FULFILLED':
      _id = action.payload.data._id;
      index = state.cards.findIndex(card => card._id === _id);
      cards = [...state.cards];
      cards.splice(index, 1);
      NotificationManager.success('Successfully Deleted Card');
      action.meta && action.meta();
      return { ...state, cards };
    case 'DELETE_CARD_REJECTED':
      NotificationManager.error('An error occured');
      return state;
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_CARD',
  payload: card.create(body),
  meta: cb
});

export const edit = (body, id, cb) => ({
  type: 'EDIT_CARD',
  payload: card.edit(body, id),
  meta: cb
});

export const del = (id, cb) => ({
  type: 'DELETE_CARD',
  payload: card.delete({}, id),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_CARDS',
  payload: card.list(body),
  meta: cb
});
