import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddGatewayModal from './AddGatewayModal';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { list, edit } from '../../reducers/gatewayReducer';
import debounce from '../../util/debounce';

const styles = theme => ({});

const Gateways = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { gateways, did_get, loading } = props;

  useEffect(() => {
    if (!did_get) props.list();
  });

  const edit = gateway => {
    props.edit(gateway, gateway._id, () => {
      setModalOpen(false);
    });
  };

  const onEdit = gateway => {
    setSelectedGateway(gateway);
    setModalOpen(true);
  };

  const headers = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Username',
      selector: 'username',
      sortable: true
    },
    {
      name: 'Serial',
      selector: 'serial',
      sortable: true
    },
    {
      name: 'Port',
      selector: 'port',
      sortable: true
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ email: keyword, full_name: keyword });
    });
  };

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddGatewayModal selectedGateway={selectedGateway} loading={loading} edit={edit} />
      </Modal>
      <Table
        search={search}
        loading={loading}
        data={gateways}
        headers={headers}
        edit={onEdit}
        title="Gateways"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.gatewayReducer.loading,
  gateways: state.gatewayReducer.gateways,
  did_get: state.gatewayReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      list,
      edit
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Gateways)
);
