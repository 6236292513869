import React, { useState, useEffect } from 'react';
import { Modal, Dialog, Chip } from '@material-ui/core';
import {
  SendOutlined,
  BluetoothSearchingOutlined,
  BluetoothDisabledOutlined,
  ListOutlined,
  InfoOutlined,
  BatteryFullOutlined,
  BatteryAlert,
  Battery60Outlined,
  Battery20Outlined,
  BarChartOutlined
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import AddLockModal from './AddLockModal';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, edit, list, del, command, list_logs, get_stats } from '../../reducers/lockReducer';
import { list as listLocations } from '../../reducers/locationReducer';
import { list as listGateways } from '../../reducers/gatewayReducer';
import moment from 'moment';
import Confirmation from '../../components/Confirmation/Confirmation';
import CommandModal from './CommandModal';
import debounce from '../../util/debounce';
import AccessLogsModal from './AccessLogsModal';
import StatsModal from './StatsModal';

const styles = theme => ({
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit / 2
  }
});

const Locks = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [accessLogsModalOpen, setAccessLogsModalOpen] = useState(false);
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const [commandModalOpen, setCommandModalOpen] = useState(false);
  const [selectedLock, setSelectedLock] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    props.get_stats();
  }, []);

  const {
    locks,
    did_get,
    did_get_locations,
    did_get_gateways,
    locations,
    gateways,
    loading,
    classes
  } = props;

  if (!did_get_gateways) {
    props.listGateways();
    props.list();
  } else if (!did_get) props.list();
  if (!did_get_locations) props.listLocations();

  const create = lock => {
    props.create(lock, () => {
      setModalOpen(false);
    });
  };

  const edit = lock => {
    props.edit(lock, lock._id, () => {
      setModalOpen(false);
    });
  };

  const del = () => {
    props.del(selectedLock._id, () => {
      setDeleteDialogOpen(false);
    });
  };

  const openCommandModal = row => {
    setSelectedLock(row);
    setCommandModalOpen(true);
  };

  const getAccessLogs = lock => {
    props.list_logs({}, lock._id, _lock => {
      setSelectedLock(_lock);
    });
  };

  const openAccessLogModal = row => {
    getAccessLogs(row);
    setAccessLogsModalOpen(true);
  };

  const openStatsModal = row => {
    props.get_stats();
    setSelectedLock(row);
    setStatsModalOpen(true);
  };

  const send_command = (command, row) => {
    if (!row) row = selectedLock;
    props.command({ command }, row._id);
  };

  const onCreate = () => {
    setSelectedLock(null);
    setModalOpen(true);
  };

  const onDelete = lock => {
    setSelectedLock(lock);
    setDeleteDialogOpen(true);
  };

  const onEdit = lock => {
    setSelectedLock(lock);
    setModalOpen(true);
  };

  const formatLockIn = data => {
    let res = '';
    const types = {
      s: 'second',
      m: 'minute',
      h: 'hour'
    };
    const { lock_in, lock_in_type } = data;
    if (lock_in === 0) return 'Disabled';
    res += lock_in + ' ' + types[lock_in_type];
    if (lock_in !== 1) res += 's';
    return res;
  };

  const headers = [
    {
      name: 'Lock Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Location',
      selector: 'location.name',
      sortable: true
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      format: data => (
        <Chip
          label={data.status || 'Unknown'}
          className={classes.chip}
          color={data.status ? (data.status === 'UNLOCKED' ? 'primary' : 'secondary') : 'default'}
        />
      )
    },
    {
      name: 'Battery Status',
      selector: 'state.battphase',
      format: data => {
        let status = data.state && data.state.battphase;
        if (!status) return 'Unknown';
        let statusNumber = parseInt('0x' + status);
        switch (statusNumber) {
          case 0:
            return (
              <div style={{ color: 'green' }}>
                <BatteryFullOutlined color="inherit" style={{ fontSize: 40 }} />
              </div>
            );
            break;
          case 1:
            return (
              <div style={{ color: 'green' }}>
                <Battery60Outlined color="inherit" style={{ fontSize: 40 }} />
              </div>
            );
          case 2:
            return (
              <div style={{ color: 'green' }}>
                <Battery20Outlined color="inherit" style={{ fontSize: 40 }} />
              </div>
            );
          case 3:
            return (
              <div style={{ color: 'red', transform: '' }}>
                <BatteryAlert color="inherit" style={{ fontSize: 40 }} />
              </div>
            );
          default:
            break;
        }
        return status;
      }
    },
    {
      name: 'Floor',
      selector: 'floor',
      sortable: true
    },
    {
      name: 'Auto Lock After',
      selector: 'lock_in',
      format: formatLockIn
    }
  ];

  const actions = [
    {
      text: 'Send Command',
      onClick: row => openCommandModal(row),
      icon: SendOutlined,
      key: 'command'
    },
    {
      text: 'Pair',
      onClick: row => send_command('PAIR', row),
      icon: BluetoothSearchingOutlined,
      key: 'pair'
    },
    {
      text: 'Unpair',
      onClick: row => send_command('UNPAIR', row),
      icon: BluetoothDisabledOutlined,
      key: 'unpair'
    },
    {
      text: 'View Logs',
      onClick: row => openAccessLogModal(row),
      icon: ListOutlined,
      key: 'logs'
    },
    {
      text: 'View Stats',
      onClick: row => openStatsModal(row),
      icon: BarChartOutlined,
      key: 'stats'
    },
    {
      text: 'Update Info',
      onClick: row => send_command('GETINFO', row),
      icon: InfoOutlined,
      key: 'info'
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ name: keyword, MAC: keyword });
    });
  };

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddLockModal
          loading={loading}
          create={create}
          edit={edit}
          selectedLock={selectedLock}
          locations={locations}
          gateways={gateways}
        />
      </Modal>
      <Modal open={accessLogsModalOpen} onClose={() => setAccessLogsModalOpen(false)}>
        <AccessLogsModal selectedLock={selectedLock} />
      </Modal>
      <Modal open={statsModalOpen} onClose={() => setStatsModalOpen(false)}>
        <StatsModal selectedLock={selectedLock} getStats={props.get_stats} stats={props.stats} />
      </Modal>
      <Modal open={commandModalOpen} onClose={() => setCommandModalOpen(false)}>
        <CommandModal loading={loading} send_command={send_command} selectedLock={selectedLock} />
      </Modal>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to delete this lock?"
          description="This action will permanently delete lock, and all connected data including passes, statistics etc."
          actions={[
            {
              text: 'Delete Permanently',
              color: 'secondary',
              onClick: () => del()
            },
            {
              text: 'Cancel',
              color: 'primary',
              onClick: () => setDeleteDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setDeleteDialogOpen(false)}
          agreeText="Delete Permanently"
          agreeAction={() => {}}
        />
      </Dialog>
      <Table
        loading={loading}
        actions={actions}
        data={locks}
        headers={headers}
        create={onCreate}
        delete={onDelete}
        search={search}
        edit={onEdit}
        title="Locks"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.lockReducer.loading,
  locks: state.lockReducer.locks,
  did_get: state.lockReducer.did_get,
  locations: state.locationReducer.locations,
  did_get_locations: state.locationReducer.did_get,
  gateways: state.gatewayReducer.gateways,
  did_get_gateways: state.gatewayReducer.did_get,
  stats: state.lockReducer.stats
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      edit,
      del,
      command,
      list_logs,
      listLocations,
      listGateways,
      get_stats
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Locks)
);
