import io from 'socket.io-client';
import { SOCKET_URL } from './config';
import { set_lock } from './reducers/lockReducer';
import { store } from './index';

let socket = null;

const add_listeners = () => {
  socket.on('connect', () => {
    authenticate();
  });

  socket.on('disconnect', () => {
    console.log('DISCONNECTED');
  });

  socket.on('UPDATE_LOCK', data => {
    store.dispatch(set_lock(data));
  });
};

const connect = () => {
  const token = localStorage.getItem('token');
  if (token) {
    socket = io(SOCKET_URL);
    add_listeners();
  }
};

const authenticate = () => {
  const token = localStorage.getItem('token');
  socket.emit('authentication', { token });
  console.log('CONNECTED');
};

export default { connect };
