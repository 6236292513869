import React from 'react';

import { Typography, withStyles, Grid, Button } from '@material-ui/core';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  marginTop: {
    marginTop: theme.spacing.unit * 3
  }
});

const commands = [
  {
    command: 'CPLIN',
    color: 'green',
    text: 'Unlock'
  },
  {
    command: 'CPLOUT',
    color: 'red',
    text: 'Lock'
  },
  {
    command: 'BUZZ',
    color: 'black',
    text: 'Buzz'
  },
  {
    command: 'LEDR',
    color: 'red',
    text: 'Led R'
  },
  {
    command: 'LEDG',
    color: 'green',
    text: 'Led G'
  },
  {
    command: 'LEDB',
    color: 'blue',
    text: 'Led B'
  }
];

const CommandModal = props => {
  const { classes } = props;

  return (
    <div className={classes.paper}>
      <Typography variant="h5">Send Command</Typography>
      <Grid container spacing={8}>
        {commands.map(command => {
          return (
            <Grid item md={4} xs={6} key={command.command}>
              <Button
                variant="outlined"
                style={{ color: command.color }}
                color="inherit"
                onClick={() => props.send_command(command.command)}
              >
                {command.text}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CommandModal);
