import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Endpoint from './Endpoint';
import { endpoints } from './endpoints';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
  }
});

function Documentation(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      {endpoints.map((endpoint, index) => {
        return <Endpoint key={index} endpoint={endpoint} />;
      })}
    </div>
  );
}

Documentation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Documentation);
