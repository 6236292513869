import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Routes from '../../Routes';
import { Switch, Route, withRouter } from 'react-router-dom';

import Locations from '../Locations/Locations';
import Users from '../Users/Users';
import Employees from '../Employees/Employees';
import Locks from '../Locks/Locks';
import Passes from '../Passes/Passes';
import Cards from '../Cards/Cards';
import CreatePass from '../Passes/CreatePass';
import Api from '../Api/Api';
import Gateways from '../Gateways/Gateways';

import sockets from '../../sockets';

sockets.connect();

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
});

class Dashboard extends React.Component {
  state = {
    open: true
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header drawerOpen={open} handleDrawerOpen={this.handleDrawerOpen} />
        <Sidebar routes={Routes} drawerOpen={open} handleDrawerClose={this.handleDrawerClose} />
        <main className={classNames(classes.content, classes.contentShift)}>
          <div className={classes.drawerHeader} />
          <Switch>
            <Route path="/dashboard/employees" component={Employees} />
            <Route path="/dashboard/users" component={Users} />
            <Route path="/dashboard/locations" component={Locations} />
            <Route path="/dashboard/locks" component={Locks} />
            <Route path="/dashboard/passes" component={Passes} />
            <Route path="/dashboard/cards" component={Cards} />
            <Route exact path="/dashboard/createpass" component={CreatePass} />
            <Route path="/dashboard/api" component={Api} />
            <Route path="/dashboard/gateways" component={Gateways} />
          </Switch>
        </main>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Dashboard));
